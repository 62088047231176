import * as Sentry from "@sentry/nuxt";

Sentry.init({
    environment: useRuntimeConfig().public.sentry.environment,
    dsn: useRuntimeConfig().public.sentry.dsn,
    release: useRuntimeConfig().public.sentry.release,
    tracesSampleRate: useRuntimeConfig().public.sentry.tracesSampleRate,
    ignoreErrors: [
        'Object.prototype.hasOwnProperty.call(o,"telephone")', //doesn't work ??
        'Cannot read properties of null (reading \'classList\')'
    ],
    beforeSend(event, hint) {
        //safari browser issue
        //https://github.com/getsentry/sentry/issues/61469
        const error = hint.originalException;
        if (
            error &&
            error.message &&
            error.message.match(/call\(e,"telephone"\)/i)
        ) {
            return null;
        }


        // if (hint?.originalException.toString().includes('call(e,"telephone")')) {
        //     return null;
        // }

        return event;
    }
});
